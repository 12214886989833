<template>
  <div class="">
    <div class="__top_navigation">
      <div class="nav_container">
        <button @click="toggleComponent('/developer/api_v1')"
          :class="[$route.path == '/developer/api_v1' ? 'active' :'']">
          API v.1.0
        </button>
        <button @click="toggleComponent('/developer/api_v2')"
          :class="[$route.path == '/developer/api_v2' ? 'active' : '']">
          API v.2.0
        </button>
      </div>
    </div>

    <!-- card container -->
    <div>
      <router-view> </router-view>
    </div>
  </div>
</template>

<script>
import Version1 from './version1.vue'
import Version2 from './version2.vue'
import Storage from '../../../services/storage.js'
let storage = new Storage()
export default {
  name: 'developer',
  components: {
    Version1,
    Version2,
  },
  data() {
    return {
      // currentComponent: 'Version2'
    }
  },
  methods: {
    toggleComponent(url) {
      if (this.$route.path !== url) {
        this.$router.push(url).catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        })
      }
    }
  },
  computed: {

  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.__top_navigation {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  ;
}

.nav_container {
  background: #F3F4F9;
  border-radius: 6px;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 2px;
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #575761;
    width: 104px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.active {
  background-color: rgba(247, 146, 28, .1) !important;
  font-weight: 500;
  color: #F7921C !important;
  border-radius: 6px;
}
</style>
